<div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="8px">
	<mat-card class="mat-elevation-z2 m4">
		<mat-card-header>
			<mat-card-title>You're not logged in!</mat-card-title>
		</mat-card-header>
		<mat-card-content fxLayout="column" fxLayoutGap="8px">
			<div>Use the CANCOM SSO to log in and get access to the reporting Dashboard.</div>
			<button (click)="login()" mat-raised-button color="primary">Login</button>
		</mat-card-content>
	</mat-card>
</div>
