import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()
export class RoleCredentialInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const session = this.userService.getSession();
		if (!session) {
			return next.handle(request);
		}

		try {
			const authReq = request.clone({
				headers: request.headers.set('authorization', `Bearer ${session.jwt}`),
			});

			return next.handle(authReq);
		} catch (e) {
			console.error(e);
		}

		return next.handle(request);
	}
}
