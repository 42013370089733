import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-external-portal-includer',
	templateUrl: './external-portal-includer.component.html',
	styleUrls: ['./external-portal-includer.component.scss'],
})
export class ExternalPortalIncluderComponent implements OnInit {
	public href: SafeResourceUrl;
	public display = 'none';

	constructor(
		private route: ActivatedRoute,
		private dom: DomSanitizer
	) {
		this.href = this.dom.bypassSecurityTrustResourceUrl(this.route.snapshot.data.path);
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.display = 'inherit';
		}, 2000);
	}
}
