import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { UserInfo } from 'src/app/app.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	public isLoggedIn = false;
	public userInfo: UserInfo | null = null;

	constructor(
		private readonly router: Router,
		private readonly keycloak: KeycloakService
	) {}

	public async ngOnInit() {
		this.isLoggedIn = await this.keycloak.isLoggedIn();

		if (this.isLoggedIn) {
			this.userInfo = (await this.keycloak.getKeycloakInstance().loadUserInfo()) as UserInfo;
			this.router.navigate(['/dashboard']);
		}
	}

	public login() {
		this.keycloak.login();
	}
}
