import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

ModuleRegistry.registerModules([
	ClientSideRowModelModule,
	ClipboardModule,
	ColumnsToolPanelModule,
	CsvExportModule,
	FiltersToolPanelModule,
	InfiniteRowModelModule,
	MasterDetailModule,
	MenuModule,
	RangeSelectionModule,
	RichSelectModule,
	RowGroupingModule,
	ServerSideRowModelModule,
	SetFilterModule,
	SideBarModule,
	ViewportRowModelModule,
]);

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
