import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
	constructor(
		protected router: Router,
		protected keycloakAngular: KeycloakService
	) {
		super(router, keycloakAngular);
	}

	isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			if (!this.authenticated) {
				this.keycloakAngular.login();
				return;
			}

			const requiredRoles = route.data.roles;
			let granted: boolean = false;
			if (!requiredRoles || requiredRoles.length === 0) {
				granted = true;
			} else {
				for (const requiredRole of requiredRoles) {
					if (this.roles.indexOf(requiredRole) > -1) {
						granted = true;
						break;
					}
				}
			}

			if (granted === false) {
				this.router.navigate(['/login']);
			}
			resolve(granted);
		});
	}
}
