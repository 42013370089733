import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';

export interface KeycloakUserProfile extends KeycloakProfile {
	attributes: { company: string };
}

@Injectable({
	providedIn: 'root',
})
export class UserProfileService {}
